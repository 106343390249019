
    import { Component, Vue, mixins } from 'nuxt-property-decorator';
    import HandleResultMixin from '@/mixins/handleResultMixin';
    import { namespace } from 'nuxt-property-decorator';
    import OptionsFields from '@/components/editor/options/OptionsFields.vue';
    import Button from '@/components/blocks/Button.vue';

    const page = namespace('page');
    const user = namespace('user');

    @Component({
        components: { OptionsFields, Button }
    })
    export default class PageWrapper extends mixins(Vue, HandleResultMixin) {
        @page.State currentPage;
        @user.State auth;
        @user.Getter canEditPages;
        @user.Getter canEditOwnPages;

        get canEditPage() {
            let result = this.auth && this.canEditPages;

            if (!result) {
                return false;
            }

            if (!this.canEditOwnPages) {
                return result;
            }

            return (
                this.currentPage.options.users.includes(this.auth.id) ||
                this.currentPage.createdBy === this.auth.id
            );
        }

        get editPageUrl() {
            if (!this.currentPage) {
                return '';
            }
            return `/admin/pages/${this.currentPage.pageId}/components`;
        }
    }
