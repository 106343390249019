
    import { Component, namespace } from 'nuxt-property-decorator';
    import { PageComponent, PageOptions } from '@/store/page';
    import components from '@/components';
    import ImageMixin from '@/mixins/imageMixin';
    import { mixins } from 'nuxt-property-decorator';
    import PageMixin from '@/mixins/PageMixin.vue';
    import PaletteStylesMixin from '@/mixins/paletteStylesMixin';
    import { UtmUtils } from '@/utils/utm';
    import PageWrapper from '@/components/editor/page/PageWrapper.vue';
    // import Hello from "@/components/Hello.vue";
    const page = namespace('page');

    @Component({
        components: { ...components, PageWrapper }
    })
    export default class Index extends mixins(ImageMixin, PaletteStylesMixin, PageMixin) {
        @page.State options: PageOptions;
        @page.State components: PageComponent[];

        async fetch({ store }) {
            await Promise.all([
                store.dispatch('entities/fetchAll', 'forms'),
                store.dispatch('entities/fetchAll', 'colors'),
                store.dispatch('page/fetch', { slug: 'index' })
            ]);
        }

        beforeMount() {
            const utmService = new UtmUtils();
            utmService.fetchUtmFromQuery(this.$route.query);
            utmService.save();
        }
    }
